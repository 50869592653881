import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common/common.service';
import { UserProgramsService } from 'src/app/services/userprograms.service';
import { environment } from 'src/environments/environment';
import { CopayDetails } from '../components/home/dashboard/copay-card/copay-card.model';
import { IActionsTiles, IGlobalSettings, IauthCriteria, IpapCriteria } from '../model/common';
import { ILocStrgUser } from '../model/user';
import { Authorization, CaseInfo, HealthPlan, PatientAddress, PatientInfo, PatientStatus, TriageDispense, UserPrograms, Userinfo, ProductInfo } from '../model/userprograms';
import { GenericCrudService } from './common/generic-crud.service';
import { LabelService } from './common/label.service';
import { ProgramDefaultService } from './common/program-default.service';

export enum MessageType {
  ReEnrollment_Initialize,
  ReEnrollment_Processing
}
@Injectable({
  providedIn: 'root'
})
export class HomeService extends GenericCrudService<any>
{
  private arr: IActionsTiles[] = [];
  private mainObj: PatientInfo = new PatientInfo();
  private globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
  userPrograms!: UserPrograms;
  public copayCardDetails!: CopayDetails;
  private _patientProgramNumber: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private titleChanged = new BehaviorSubject<any>('');
  public titleChanged$ = this.titleChanged.asObservable();

  private programSelctionChanged = new BehaviorSubject<any>(false);
  public programSelctionChanged$ = this.programSelctionChanged.asObservable();

  private titleNumberChanged = new BehaviorSubject<any>('');
  public titleNumberChanged$ = this.titleNumberChanged.asObservable();

  private contentTitleChanged = new BehaviorSubject<any>('');
  public contentTitleChanged$ = this.contentTitleChanged.asObservable();

  private breadcrumbTitleChanged = new BehaviorSubject<any>('');
  public breadcrumbTitleChanged$ = this.breadcrumbTitleChanged.asObservable();

  private messageChanged = new BehaviorSubject<any>('');
  public messageChanged$ = this.messageChanged.asObservable();

  private titleImgChanged = new BehaviorSubject<any>('');
  public titleImgChanged$ = this.titleImgChanged.asObservable();

  private imgWidthChanged = new BehaviorSubject<any>('');
  public imgWidthChanged$ = this.imgWidthChanged.asObservable();

  private imgHeightChanged = new BehaviorSubject<any>('');
  public imgHeightChanged$ = this.imgHeightChanged.asObservable();

  private showErrorMsg = new BehaviorSubject<any>('');
  public showErrorMsg$ = this.showErrorMsg.asObservable();

  private userObjectLoaded = new BehaviorSubject<any>('');
  public userObjectLoaded$ = this.userObjectLoaded.asObservable();
  private displayDetailedProfile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private displayHistory: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private displayInsurance: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private displayDispenseDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private displayCopayDetails: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private displayNoActionItemsMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private displayHistoryLink: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _displayUpdateProfileSuccessMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _displayUpdateProfileErrorMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _displayMissingInfoTile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public expiration_date : Date= new Date;

  constructor (http: HttpClient, public userProgramsService: UserProgramsService, public service: CommonService, private programDefaults: ProgramDefaultService, private labelService: LabelService) {
    super(http, environment.serviceUrl);
  }


  public userGet() {
    return this.getAll('api/patient');
  }

  public getUser() {
    return this.get('api/patient');
  }

  public getCopayCaseId(): string {
    let caseId: string = '';
    let product_details = this.userProgramsService.getProductInfo(true);
    this.getUserInfo().cases.forEach((e: CaseInfo) => {
      if (
        (e.status.toUpperCase() === 'APPROVED') && (e.endDate === '') && e.programProductId === product_details.programProductId &&
        (e.type.toUpperCase() === 'COPAY')
      ) {
        caseId = e.caseId;

      }
    });
    return caseId;
  }
  public getCopayCardDetails(caseId: string): Observable<any> {
    return this.getAll('api/patient/copay/details?caseId=' + caseId + '&programId=' + this.service.getProgramId());
  }

  /**
   * Toggle the updateMessage specifically for the MyProfilePage after update.
   * @param {boolean} flag Boolean to show message or not.
   */
  public setUpdateProfileSuccessMessage(flag: boolean) {
    this._displayUpdateProfileSuccessMessage.next(flag);
  }

  /**
   * Toggle the updateMessage specifically for the MyProfilePage after update.
   * @param {boolean} flag Boolean to show message or not.
   */
  public setUpdateProfileErrorMessage(flag: boolean) {
    this._displayUpdateProfileErrorMessage.next(flag);
  }

  /**
   * Gets the messages for the update profile outcome. Displays that message on the Dashboard.
   */
  public showUpdateProfileMessage(): void {
    const _labels = this.labelService.getComponentLabels('UpdateMyProfileComponent');
    const success: string = _labels.get('UpdateSuccess') || '';
    const error: string = _labels.get('UpdateError') || '';

    if (this._displayUpdateProfileSuccessMessage.value) {
      this.updateMessage(success);
    }
    if (this._displayUpdateProfileErrorMessage.value) {
      this.updateMessage(error);
    }
  }

  get patientNumber() {
    this._patientProgramNumber.next(this.getUserInfo().cases.filter(c => c.patientProgramNumber !== undefined || c.patientProgramNumber !== '')[0].patientProgramNumber);
    return this._patientProgramNumber;
  }

  setPatientNumber(patientProgramId: string) {
    this._patientProgramNumber.next(patientProgramId);
  }

  public titleMessage(msg: any) {
    this.titleChanged.next(msg);
  }

  public setProgramSelection(msg: any) {
    this.programSelctionChanged.next(msg);
  }

  public objectLoaded(msg: any) {
    this.userObjectLoaded.next(msg);
  }

  public titleNumber(msg: any) {
    this.titleNumberChanged.next(msg);
  }

  public contentTitle(msg: any) {
    this.contentTitleChanged.next(msg);
  }

  public breadcrumbTitle(msg: any) {
    this.breadcrumbTitleChanged.next(msg);
  }

  public titleImage(msg: any) {
    this.titleImgChanged.next(msg);
  }

  public imageWidth(msg: any) {
    this.imgWidthChanged.next(msg);
  }

  public imageHeight(msg: any) {
    this.imgHeightChanged.next(msg);
  }

  public logout() {
    return this.post(null, 'api/User/Logout');
  }

  public showErrorOnHome(msg: any) {
    this.showErrorMsg.next(msg);
  }


  public updateMessage(msg: any) {
    this.messageChanged.next(msg);
  }

  get programId(): number {
    return this.userPrograms.programs?.filter((y:any)=> y.ProgramId === this.service.getProgramId())[0].ProgramId;
  }

  public getUserInfo() {
    let userObj: PatientInfo = new PatientInfo();
    let user: Userinfo = new Userinfo;
    this.userPrograms = this.userProgramsService.getUserPrograms();
    const localUser: ILocStrgUser = JSON.parse(sessionStorage.getItem("user")!);
    if (this.userPrograms != null) {
      userObj = this.userProgramsService.getPatientInfo();
      let address: PatientAddress;
      if (userObj.address) {
        address = userObj.address.find((x: PatientAddress) => x.patientPrimaryAddress == true)!;
      }
      user = {
        info: userObj.patient,
        address: address!,
        prescriber: userObj.healthCareProvider,
        cases: userObj.caseInfo,
        productInfo: userObj.productInfo,
        history: userObj.statuses || [],
        insurance: userObj.insurances || [],
        userName: localUser.name
      };
    }
    return user;
  }

  /**
   * Get the Triage and Dispense info for patients with active PAP.
   * @returns Object with an array of Dispense objects and an array of Triage objects.
   */
  public getPapPatientTriageDispense(): TriageDispense {
    const { dispense, triage, ...PatientInfo } = this.userProgramsService.getPatientInfo();
    const hasPAP = this.hasActivePap(PatientInfo.caseInfo);
    return hasPAP ? { dispense, triage } : { dispense: [], triage: [] }
  }

  /**
   * Determine if Patient has active PAP enrollment that is not expired.
   * @param cases {CaseInfo[]} Array of CaseInfo objects.
   * @returns {boolean} True if patient has active PAP that is not expired.
   */
  public hasActivePap(cases: CaseInfo[]): boolean {
    const today: Date = new Date();
    let hasPap = false;
    for (const c of cases) {
      if ((c.type === 'PAP' || c.type ==='HAP') && c.status.toLowerCase() === 'approved') {
        const dateParts = c.endDate.split('-');
        const endDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));
        hasPap = c.endDate !== '' && endDate > today ? true : false;
      }
    }
    return hasPap;
  }

  public getActions() {
    let papCriteria: IpapCriteria[] = [];
    let copayPapCriteria: IpapCriteria[] = [];
    let copayCriteria: IpapCriteria[] = [];
    let hapCriteria: IpapCriteria[] = [];
    let copayHapCriteria: IpapCriteria[] = [];
    let hipaaCriteria: IauthCriteria[] = [];
    let marketingCriteria: IauthCriteria[] = [];
    let incomeCriteria: IauthCriteria[] = [];
    let papReEnrollmentCriteria: IpapCriteria[] = [];
    let hapReEnrollmentCriteria: IpapCriteria[] = [];
    let copayPAPReEnrollmentCriteria: IpapCriteria[] = [];
    let copayHAPReEnrollmentCriteria: IpapCriteria[] = [];
    let incomePAPEnrollmentCriteria: IpapCriteria[] = [];
    let incomeHAPEnrollmentCriteria: IpapCriteria[] = [];
    let copayGovCriteria: any[] = [];

    this.userPrograms = this.userProgramsService.getUserPrograms();
    if (this.userPrograms != null) {
      this.mainObj = this.userProgramsService.getPatientInfo();

      let pap = this.programDefaults.getProgramDefaultByName('PAPCriteria');
      if (pap && pap.length > 0) {
        papCriteria = JSON.parse(pap[0].Value);
      }
      let hap = this.programDefaults.getProgramDefaultByName('HAPCriteria');
      if (hap && hap.length > 0) {
        hapCriteria = JSON.parse(hap[0].Value);
      }
      let copayPap = this.programDefaults.getProgramDefaultByName('COPAYPAPCriteria');
      if (copayPap && copayPap.length > 0) {
        copayPapCriteria = JSON.parse(copayPap[0].Value);
      }
      let copayHap = this.programDefaults.getProgramDefaultByName('COPAYHAPCriteria');
      if (copayHap && copayHap.length > 0) {
        copayHapCriteria = JSON.parse(copayHap[0].Value);
      }
      let copay = this.programDefaults.getProgramDefaultByName('COPAYCriteria');
      if (copay && copay.length > 0) {
        copayCriteria = JSON.parse(copay[0].Value);
      }
      let hipaa = this.programDefaults.getProgramDefaultByName('HIPPACriteria');
      if (hipaa && hipaa.length > 0) {
        hipaaCriteria = JSON.parse(hipaa[0].Value);
      }
      let marketing = this.programDefaults.getProgramDefaultByName('MarketingCriteria');
      if (marketing && marketing.length > 0) {
        marketingCriteria = JSON.parse(marketing[0].Value);
      }
      let income = this.programDefaults.getProgramDefaultByName('IncomeCriteria');
      if (income && income.length > 0) {
        incomeCriteria = JSON.parse(income[0].Value);
      }
      let papReEnrollment = this.programDefaults.getProgramDefaultByName('PAPReEnrollmentCriteria');
      if (papReEnrollment && papReEnrollment.length > 0) {
        papReEnrollmentCriteria = JSON.parse(papReEnrollment[0].Value);
      }
      let hapReEnrollment = this.programDefaults.getProgramDefaultByName('HAPReEnrollmentCriteria');
      if (hapReEnrollment && hapReEnrollment.length > 0) {
        hapReEnrollmentCriteria = JSON.parse(hapReEnrollment[0].Value);
      }
      let copayPAPReEnrollment = this.programDefaults.getProgramDefaultByName('COPAYPAPReEnrollmentCriteria');
      if (copayPAPReEnrollment && copayPAPReEnrollment.length > 0) {
        copayPAPReEnrollmentCriteria = JSON.parse(copayPAPReEnrollment[0].Value);
      }
      let copayHAPReEnrollment = this.programDefaults.getProgramDefaultByName('COPAYHAPReEnrollmentCriteria');
      if (copayHAPReEnrollment && copayHAPReEnrollment.length > 0) {
        copayHAPReEnrollmentCriteria = JSON.parse(copayHAPReEnrollment[0].Value);
      }
      let incomePAPEnrollment = this.programDefaults.getProgramDefaultByName('INCOMEPAPCriteria');
      if (incomePAPEnrollment && incomePAPEnrollment.length > 0) {
        incomePAPEnrollmentCriteria = JSON.parse(incomePAPEnrollment[0].Value);
      }
      let incomeHAPEnrollment = this.programDefaults.getProgramDefaultByName('INCOMEHAPCriteria');
      if (incomeHAPEnrollment && incomeHAPEnrollment.length > 0) {
        incomeHAPEnrollmentCriteria = JSON.parse(incomeHAPEnrollment[0].Value);
      }
      let copayGov = this.programDefaults.getProgramDefaultByName('COPAYGovCriteria');
      if (copayGov && copayGov.length > 0) {
        copayGovCriteria = JSON.parse(copayGov[0].Value);
      }

      this.arr = this.programDefaults.ProgramServicesType;
      //Filter array based on object
      if (this.mainObj) {
        let program = this.mainObj;
        if (!this.userProgramsService.checkProductInfo()) {
          this.removeItemsWithShortName(this.arr, this.service.serviceNames.hipaa);
          this.removeItemsWithShortName(this.arr, this.service.serviceNames.marketingHippa);
          this.removeItemsWithShortName(this.arr, this.service.serviceNames.income);
          this.removeItemsWithShortName(this.arr, this.service.serviceNames.copay);
        }
        else if (program && program.authorization && program.authorization.length > 0) {
          //For HIPPA,Do not create a tile if authorization received has type= HIPAA Source = Electronic or Source = Written and Status = Received and active=true
          let hipaaMeetCriteria = this.getAuthCaseCriteria(hipaaCriteria, program, this.service.serviceNames.hipaa);

          if (!hipaaMeetCriteria) {
            //exclude hipaa from action tiles
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.hipaa);
          }
          //For Marketing, Do not create a tile if authorization received has type = Marketing Source = Electronic or Source = Written and Status = Received and active=true
          let marketingMeetCriteria = this.getAuthCaseCriteria(marketingCriteria, program, this.service.serviceNames.marketing);
          if (!marketingMeetCriteria) {
            //exclude marketing from action tiles
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.marketingHippa);
          }


        }

        let incomePAPExclusion = this.getCaseCriteria(incomePAPEnrollmentCriteria, program, this.service.serviceNames.pap, this.userProgramsService.getProductInfo().programProductId);
        let incomeHAPExclusion = this.getCaseCriteria(incomeHAPEnrollmentCriteria, program, this.service.serviceNames.hap, this.userProgramsService.getProductInfo().programProductId);

        //For Income, Do not create a tile if authorization received has type = Electronic Income Source = Electronic or Source = Written and Status = Received and active=true
        let incomeMeetCriteria = this.getAuthCaseCriteria(incomeCriteria, program, this.service.serviceNames.income);


        if ((incomePAPExclusion == false && incomeHAPExclusion == false) || ((incomePAPExclusion || incomeHAPExclusion) && !incomeMeetCriteria))
          this.removeItemsWithShortName(this.arr, this.service.serviceNames.income);

        if (program && program.caseInfo && program.caseInfo.length > 0) {
          let product_details = this.userProgramsService.getProductInfo()
          let ProductInfo = program.productInfo.find((v: ProductInfo) => (v.typeOfEnrollment.toUpperCase() == this.service.serviceNames.papReEnrollment.toUpperCase() 
          || v.typeOfEnrollment.toUpperCase() == this.service.serviceNames.hapReEnrollment.toUpperCase() 
          || v.typeOfEnrollment.toUpperCase() == this.service.serviceNames.enrollment.toUpperCase()));
      
          if(ProductInfo!= undefined){
            if((ProductInfo.typeOfEnrollment.toUpperCase() == this.service.serviceNames.papReEnrollment.toUpperCase()) 
            || (ProductInfo.typeOfEnrollment.toUpperCase() == this.service.serviceNames.hapReEnrollment.toUpperCase())
            || (ProductInfo.typeOfEnrollment.toUpperCase() == this.service.serviceNames.enrollment.toUpperCase())
            ){
              this.getEnablePAPHAPTile(program);
            }else if (ProductInfo.typeOfEnrollment == this.service.serviceNames.copay) {
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.pap);
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.hap);
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.papReEnrollment);
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.hapReEnrollment);
          }
          else {
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.pap);
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.hap);
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.papReEnrollment);
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.hapReEnrollment);
          }
        }
          if (copayCriteria.length > 0) {
            let copayExclusion = this.getCaseCriteria(copayCriteria, program, this.service.serviceNames.copay, product_details.programProductId);
            //Change from isCovHealthPlan to isCommHealthPlan .. condition changes from checking true to false.
            var isCommHealthPlan = this.checkCommercialPBMHealthPlan(copayGovCriteria, program);
            if (!isCommHealthPlan) {
              this.removeItemsWithShortName(this.arr, this.service.serviceNames.copay);
            }
            else if (copayPAPReEnrollmentCriteria.length > 0 || copayHAPReEnrollmentCriteria.length > 0) {
              let papExclusion = this.getCaseCriteria(copayPAPReEnrollmentCriteria, program, this.service.serviceNames.pap, product_details.programProductId);
              let hapExclusion = this.getCaseCriteria(copayHAPReEnrollmentCriteria, program, this.service.serviceNames.hap, product_details.programProductId);
              if (copayExclusion || papExclusion || hapExclusion) {
                //exclude copay from action tiles
                this.removeItemsWithShortName(this.arr, this.service.serviceNames.copay);
              }
            }
          }
          //Check for Copay-Dont show copay tile if pap tile exists or if Copay Case status = Approved, Reason = Qualified exists
        }
      }
      if (this.arr.length == 0) {
        this.titleNumber('0');
      }
      else {
        this.titleNumber(this.arr.length);
      }


    }

    return this.arr;
  }

  public checkHealthPlan(copayGovCriteria: any, program: PatientInfo) {
    let isGovHealthPlan = false;

    if (copayGovCriteria && program.healthPlan) {
      for (let criteria of copayGovCriteria) {
        let copayGov = program.healthPlan.find((v: HealthPlan) => v.healthPlanType.replace(' ', '') == criteria.PlanType.replace(' ', '')
          && v.insuranceType.replace(' ', '') == criteria.InsuranceType.replace(' ', '') && v.status.replace(' ', '') == criteria.Status.replace(' ', ''));
        if (copayGov) {
          isGovHealthPlan = true;
          break;
        }
      }
    }
    return isGovHealthPlan;
  }
  public checkCommercialPBMHealthPlan(copayGovCriteria: any, program: PatientInfo) {
      let isCommercialPBM = false;

      if (copayGovCriteria && program.healthPlan) {
        for (var criteria of copayGovCriteria) {
          let copayCommercial = program.healthPlan.find((v: HealthPlan) => v.healthPlanType.replace(' ', '') == criteria.PlanType.replace(' ', '')
            && v.insuranceType.replace(' ', '') == criteria.InsuranceType.replace(' ', '') && v.status.replace(' ', '') == criteria.Status.replace(' ', ''));
          if (copayCommercial) {
            isCommercialPBM = true;
            break;
          }
        }
      }
    return isCommercialPBM;
  }

  public getHealthPlans(): HealthPlan[] {
    return this.userProgramsService.getUserPrograms().programs?.filter((y:any)=> y.ProgramId == this.service.getProgramId())[0].PatientInfo.healthPlan;
  }


  public getAuthCaseCriteria(authCriteria: any, program: PatientInfo, type: string) {
    let meetsCriteria = false;
 
    if (!program.authorization.find((v: Authorization) => v.type.replace(' ', '') == type.replace(' ', '') && v.type.replace(' ', '') !== 'HubServices')) {
      return true;
    }
    for (let criteria of authCriteria) {
      let hipaaInclusion = undefined;
      if (criteria.source == 'Verbal' || criteria.source == 'none') {
        hipaaInclusion = program.authorization.find((v: Authorization) => v.type.replace(' ', '') == type.replace(' ', '')
          && v.source.replace(' ', '') == criteria.source.replace(' ', ''));
      }
      else if(type.replace(' ', '') ==  'Marketing' && program.authorization.length > 0){
        const marketingNonSMS = !program.authorization.find((v: Authorization) => v.type.replace(' ', '') === type.replace(' ', '')
                           && v.methodOfCommunication === 'SMS' )
                                 
        const MarketingExpirationDays = this.programDefaults.getProgramDefaultByName('MarketingExpirationDays');
        const HubServicesExpirationDays = this.programDefaults.getProgramDefaultByName('HubServicesExpirationDays');
       
        const marketingLessThanOneYear = program.authorization.find((v: Authorization) => v.type.replace(' ', '') == type.replace(' ', '')    
                           && v.methodOfCommunication === 'SMS'  
                           && this.calculateDateDifference(new Date(v.authExpirationDate)) <= parseInt(MarketingExpirationDays[0].Value))  // 365 Days = 1 Year
 
        const hubServiceLessthanNintyDays = program.authorization.find((v: Authorization) => (v.type.replace(' ', '') === 'HubServices')
                           && (this.calculateDateDifference(new Date(v.authExpirationDate)) <= parseInt(HubServicesExpirationDays[0].Value)));  // 90 Days
        
        let hubServicesCondition : any;   // condition to check if HubServices is inactive and not available
        if( program.authorization.find((v: Authorization) => (v.type.replace(' ','') === 'HubServices' )) )
        {
          hubServicesCondition = false;
        }
        else{
          hubServicesCondition = true;
        }

        hipaaInclusion = marketingNonSMS ||  marketingLessThanOneYear  || hubServicesCondition || hubServiceLessthanNintyDays;
 
      }
      else {
        let criteriainactive = Number(criteria.inactive)
        let criteriainactiveduration = criteria.inactiveduration
        hipaaInclusion = program.authorization.find((v: Authorization) => v.type.replace(' ', '') == type.replace(' ', '')
          && v.source.replace(' ', '') == criteria.source.replace(' ', '')
          && v.status.replace(' ', '') == criteria.status.replace(' ', '')
          && (criteria.inactive != undefined && criteria.inactiveduration != undefined) ? moment(v.authExpirationDate).isBefore(moment().add(criteriainactive, criteriainactiveduration)) : false);
      }
      if (hipaaInclusion) {
        meetsCriteria = true;
        break;
      }
    }
    return meetsCriteria;
  }

  calculateDateDifference(expirationDate : Date) : number {
    const currentDate = new Date();
    const diffInMilliseconds = expirationDate.getTime() - currentDate.getTime();
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
    const roundedDiffInDays = Math.ceil(diffInDays);
    return roundedDiffInDays;
  }

  public getEnablePAPHAPTile(program: PatientInfo) {
    let ProductInfo = program.productInfo.find((v: ProductInfo) => (v.typeOfEnrollment.toUpperCase() == this.service.serviceNames.papReEnrollment.toUpperCase() 
    || v.typeOfEnrollment.toUpperCase() == this.service.serviceNames.hapReEnrollment.toUpperCase() 
    || v.typeOfEnrollment.toUpperCase() == this.service.serviceNames.enrollment.toUpperCase()));

    let paphapInclusion = program.caseInfo.find((v: CaseInfo) => (v.type.toUpperCase() == this.service.serviceNames.pap.toUpperCase() 
        || v.type.toUpperCase() == this.service.serviceNames.hap.toUpperCase()));

    if(ProductInfo!= undefined){
      if((ProductInfo.typeOfEnrollment.toUpperCase() == this.service.serviceNames.papReEnrollment.toUpperCase()) 
      || (ProductInfo.typeOfEnrollment.toUpperCase() == this.service.serviceNames.hapReEnrollment.toUpperCase())){
        this.removeItemsWithShortName(this.arr, this.service.serviceNames.pap);
        this.removeItemsWithShortName(this.arr, this.service.serviceNames.hap);        

        if(paphapInclusion != undefined){
          if(paphapInclusion.type.toUpperCase() == this.service.serviceNames.pap.toUpperCase()){
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.hapReEnrollment);
            if(paphapInclusion.status == "Approved" || paphapInclusion.status == "Not Approved"  || paphapInclusion.status == "Conditionally Approved"){
              this.removeItemsWithShortName(this.arr, this.service.serviceNames.papReEnrollment);
            }
          }
          else if(paphapInclusion.type.toUpperCase() == this.service.serviceNames.hap.toUpperCase()){
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.papReEnrollment);
            if(paphapInclusion.status == "Approved" || paphapInclusion.status == "Not Approved" || paphapInclusion.status == "Conditionally Approved"){
              this.removeItemsWithShortName(this.arr, this.service.serviceNames.hapReEnrollment);
            }
          }
        }else{
          this.removeItemsWithShortName(this.arr, this.service.serviceNames.papReEnrollment);
          this.removeItemsWithShortName(this.arr, this.service.serviceNames.hapReEnrollment);
        }
      }else if(ProductInfo.typeOfEnrollment.toUpperCase() == this.service.serviceNames.enrollment.toUpperCase()){
        this.removeItemsWithShortName(this.arr, this.service.serviceNames.papReEnrollment);
        this.removeItemsWithShortName(this.arr, this.service.serviceNames.hapReEnrollment);

        if(paphapInclusion != undefined){
          if(paphapInclusion.type.toUpperCase() == this.service.serviceNames.pap.toUpperCase()){
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.hap);
            if(paphapInclusion.status == "Approved" || paphapInclusion.status == "Not Approved" || paphapInclusion.status == "Conditionally Approved"){
              this.removeItemsWithShortName(this.arr, this.service.serviceNames.pap);
            }
          }
          else if(paphapInclusion.type.toUpperCase() == this.service.serviceNames.hap.toUpperCase()){
            this.removeItemsWithShortName(this.arr, this.service.serviceNames.pap);
            if(paphapInclusion.status == "Approved" || paphapInclusion.status == "Not Approved" || paphapInclusion.status == "Conditionally Approved"){
              this.removeItemsWithShortName(this.arr, this.service.serviceNames.hap);
            }
          }
        }else{
          this.removeItemsWithShortName(this.arr, this.service.serviceNames.pap);
          this.removeItemsWithShortName(this.arr, this.service.serviceNames.hap);
        }
      }
    }
  }

  public getCaseCriteria(papCriteria: IpapCriteria[], program: PatientInfo, type: string, programProduct: string) {
    let meetsCriteria = false;
    for (let criteria of papCriteria) {
      if (criteria.reasonFlag == "true" && criteria.subreasonFlag == "false") {
        let papInclusion = program.caseInfo.find((v: CaseInfo) => v.programProductId == programProduct
          && v.reason.replace(' ', '').toLowerCase() == criteria.Reason.replace(' ', '').toLowerCase()
          && v.status.replace(' ', '').toLowerCase() == criteria.Status.replace(' ', '').toLowerCase()
          && v.type.toLowerCase() == type.toLowerCase());
        if (papInclusion != undefined) {
          meetsCriteria = true;
          break;
        }
      }
      if (criteria.reasonFlag == "true" && criteria.subreasonFlag == "true") {
        let papInclusion = program.caseInfo.find((v: CaseInfo) => v.programProductId == programProduct &&
          v.status.replace(' ', '').toLowerCase() == criteria.Status.replace(' ', '').toLowerCase() &&
          v.reason.replace(' ', '').toLowerCase() == criteria.Reason.replace(' ', '').toLowerCase() &&
          v.subreason.replace(' ', '').toLowerCase() == criteria.SubReason.replace(' ', '').toLowerCase() &&
          v.type.toLowerCase() == type.toLowerCase());
        if (papInclusion != undefined) {
          meetsCriteria = true;
          break;
        }
      }
      if (criteria.reasonFlag == "false" && criteria.subreasonFlag == "false") {
        let papInclusion = program.caseInfo.find((v: CaseInfo) => v.programProductId == programProduct && v.status.replace(' ', '').toLowerCase() == criteria.Status.replace(' ', '').toLowerCase() && v.type.toLowerCase() == type.toLowerCase());
        if (papInclusion != undefined) {
          meetsCriteria = true;
          break;
        }
      }

    }
    return meetsCriteria;
  }

  public getPatientStatus() {
    let status: PatientStatus[] = [];
    this.userPrograms = this.userProgramsService.getUserPrograms();
    if (this.userPrograms != null) {
      this.mainObj = this.userProgramsService.getPatientInfo()
    }
    if (sessionStorage.getItem('GlobalSettings') != null) {
      const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
      if (globalSettings) {
        this.arr = globalSettings.Programs?.filter((y:any)=> y.ProgramId == this.service.getProgramId())[0].ProgramServiceTypes;
        if (this.mainObj) {
          let program = this.mainObj;
          if (program && program.patientStatus.length > 0) {
            status = program.patientStatus;
          }
        }

      }
    }
    return status;
  }

  public hasCaseType(caseType: string, programProduct: string): boolean {
    let cases = this.getCases(caseType, programProduct);
    if (cases == null) {
      return false;
    }
    return true;
  }


  public CaseReturn(caseType: string, programProduct: string): CaseInfo | undefined | null {
    return this.getCases(caseType, programProduct);
  }

  private getCases(caseType: string, programProduct: string): CaseInfo | undefined | null {
    this.userPrograms = this.userProgramsService.getUserPrograms();
    if (this.userPrograms !== null) {
      this.mainObj = this.userProgramsService.getPatientInfo();
    }

    if (this.mainObj === null) {
      return null;
    }

    return this.mainObj.caseInfo.find((x: CaseInfo) => x.type.toLowerCase() === caseType.toLowerCase() && x.programProductId === programProduct);
  }

  /**
 * Set feature flag to display Copay Details component on Dashboard.
 * @returns {BehaviorSubject<boolean>} True if Copay Details Component should be displayed.
 */
  public getCopayCardDetailsStatus(): BehaviorSubject<boolean> {
    const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
    const flag = globalSettings.Programs?.filter((y:any)=> y.ProgramId == this.service.getProgramId())[0].ProgramDefaults.filter(x => x.Field === 'DisplayCopayCardDetails');
    if (flag[0]) {
      this.displayCopayDetails.next(flag[0].Value.toLowerCase() === 'true');
    }
    return this.displayCopayDetails;
  }

  public getDisplayMissingInfoTile(): BehaviorSubject<boolean> {
    const flag = this.globalSettings.Programs?.filter((y: any) => y.ProgramId === this.service.getProgramId())[0].ProgramDefaults.filter(x => x.Field === 'DisplayMissingInformationTile');
    if (flag[0]) {
      this._displayMissingInfoTile.next(flag[0].Value.toLowerCase() === 'true');
    }
    return this._displayMissingInfoTile;
  }

  /**
   * Set feature flag to display MyHistory component on Dashboard.
   * @returns {BehaviorSubject<boolean>} True if MyHistory Component should be displayed.
   */
  public getHistoryStatus(): BehaviorSubject<boolean> {
    const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
    const flag = globalSettings.Programs?.filter((y:any)=> y.ProgramId == this.service.getProgramId())[0].ProgramDefaults.filter(x => x.Field === 'DisplayHistory');
    if (flag[0]) {
      this.displayHistory.next(flag[0].Value.toLowerCase() === 'true');
    }
    return this.displayHistory;
  }

  /**
   * Set feature flag to display InsuranceDetails component on Dashboard.
   * @returns {BehaviorSubject<boolean>} True is Insurance Details Component should be displayed.
   */
  public getInsuranceStatus(): BehaviorSubject<boolean> {
    const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
    const flag = globalSettings.Programs?.filter((y:any)=> y.ProgramId == this.service.getProgramId())[0].ProgramDefaults.filter(x => x.Field === 'DisplayInsuranceDetails');
    if (flag[0]) {
      this.displayInsurance.next(flag[0].Value.toLowerCase() === 'true');
    }
    return this.displayInsurance;
  }

  /**
   * Set feature flag to display DispenseDetails Component on the Dashboard.
   * @returns {BehaviorSubject<boolean>} True is Dispense Details Component should be displayed.
   */
  public getDispenseStatus(): BehaviorSubject<boolean> {
    const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
    const flag = globalSettings.Programs?.filter((y:any)=> y.ProgramId == this.service.getProgramId())[0].ProgramDefaults.filter(x => x.Field === 'DisplayDispenseDetails');
    if (flag[0]) {
      this.displayDispenseDetails.next(flag[0].Value.toLowerCase() === 'true');
    }
    return this.displayDispenseDetails;
  }

  /**
   * Set feature flag to display NoActionItemsMessage in ActionsComponent on the Dashboard.
   * @returns {BehaviorSubject<boolean>} True is NoActionItemsMessage should be displayed.
   */
  public getDisplayActionItemsMessage(): BehaviorSubject<boolean> {
    const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
    const flag = globalSettings.Programs?.filter((y:any)=> y.ProgramId == this.service.getProgramId())[0].ProgramDefaults.filter(x => x.Field === 'DisplayNoActionItemsMessage');
    if (flag[0]) {
      this.displayNoActionItemsMessage.next(flag[0].Value.toLowerCase() === 'true');
    }
    return this.displayNoActionItemsMessage;
  }

  public getDisplayDetailedProfile(): BehaviorSubject<boolean> {
    const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
    const flag = globalSettings.Programs?.filter((y:any)=> y.ProgramId == this.service.getProgramId())[0].ProgramDefaults.filter(x => x.Field === 'DisplayDetailedUserProfile');
    if (flag[0]) {
      this.displayDetailedProfile.next(flag[0].Value.toLowerCase() === 'true');
    }
    return this.displayDetailedProfile;
  }

  public getHistoryLink(): BehaviorSubject<boolean> {
    const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
    const flag = globalSettings.Programs?.filter((y:any)=> y.ProgramId == this.service.getProgramId())[0].ProgramDefaults.filter(x => x.Field === 'DisplayHistoryLink');
    if (flag[0]) {
      this.displayHistoryLink.next(flag[0].Value.toLowerCase() === 'true');
    }
    return this.displayHistoryLink;
  }

  public getCopayStatus() {
    this.userPrograms = this.userProgramsService.getUserPrograms();
    if (this.userPrograms != null) {
      this.mainObj = this.userProgramsService.getPatientInfo();
    }

    if (this.mainObj == null) {
      return null;
    }

    return this.mainObj.caseInfo.find((x: CaseInfo) => x.type == 'Copay' && x.status == 'Approved');
  }

  removeItemsWithShortName(items: any, name: string): void {
    for (let i = 0; i < items.length; i++) {
      if (items[i].ServiceShortName === name) {
        items.splice(i--, 1);
      }
    }
    this.arr = items;
  }

  removeElementsByClass(errorclassName: string, successclassName: string) {
    let errorElements = document.getElementsByClassName(errorclassName);
    if (errorElements.length > 0) {
      errorElements[0]!.children[0]!.innerHTML = "";
    }

    let successElements = document.getElementsByClassName(successclassName);
    if (successElements.length > 0) {
      successElements[0]!.children[0]!.innerHTML = "";
    }
  }



}
